<template>
  <div>
    <v-form>
      <p class="mb-3">
        Determine whether each of the following statements regarding TLC is true or false.
      </p>

      <div v-for="question in questions" :key="question.vModel" class="mb-1">
        <p class="mb-n3">
          <stemble-latex :content="question.text" />
        </p>
        <v-radio-group
          v-model="inputs[question.vModel]"
          :disabled="!allowEditing"
          class="mb-0 pl-6"
        >
          <v-radio
            v-for="option in options"
            :key="'pt-1-' + option.value"
            class="my-1"
            :value="option.value"
          >
            <template #label>
              <stemble-latex :content="option.text" />
            </template>
          </v-radio>
        </v-radio-group>
      </div>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'ChemUCI51LBA7_Q2',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        answerRf: null,
        answerPolar: null,
        answerRange: null,
        answerLessPolar: null,
      },
      questions: [
        {
          text: 'a) $\\text{R}_\\text{f}$ is dependent on polarity',
          vModel: 'answerRf',
        },
        {
          text: 'b) Polar molecules have higher $\\text{R}_\\text{f}$ values than non-polar molecules under the same developing conditions',
          vModel: 'answerPolar',
        },
        {
          text: 'c) $\\text{R}_\\text{f}$ values should be kept in the range of 0.2 to 0.8 for the most effective separation when more than one compound is present',
          vModel: 'answerRange',
        },
        {
          text: 'd) The less polar the solvent, the higher the $\\text{R}_\\text{f}$ values',
          vModel: 'answerLessPolar',
        },
      ],
      options: [
        {
          text: 'True',
          value: 'true',
        },
        {
          text: 'False',
          value: 'false',
        },
      ],
    };
  },
};
</script>
